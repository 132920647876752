import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h1>
    <p>{`Let's explore the most common tasks you'll likely need on a day to day basis.`}</p>
    <h2 {...{
      "id": "accessing-the-calendar"
    }}>{`Accessing The Calendar`}</h2>
    <p>{`Your calendar URL will be provided to you by a member of your organisation. It's usually structured as events.mylibrary.com, and the staff portal can be found by appending /staff to the end, for example: events.mylibrary.com/staff. This might be one worth bookmarking! You can also use the 'Staff' link in the Calendar homepage's footer menu to log in.`}</p>
    <h2 {...{
      "id": "log-in-as-a-staff-member"
    }}>{`Log in as a Staff Member`}</h2>
    <p>{`Log in as a staff member using your dedicated platform credentials, or with your Microsoft 365 credentials if enabled by your organisation.`}</p>
    <ol>
      <li parentName="ol">{`Enter your username`}</li>
      <li parentName="ol">{`Enter your password`}</li>
    </ol>
    <h2 {...{
      "id": "log-in-with-microsoft-365"
    }}>{`Log in with Microsoft 365`}</h2>
    <ol>
      <li parentName="ol">{`Click 'Log in With Microsoft'`}</li>
      <li parentName="ol">{`Enter your work Microsoft email address`}</li>
      <li parentName="ol">{`Enter your work Microsoft password`}</li>
    </ol>
    <h3 {...{
      "id": "watch-the-video"
    }}>{`Watch The Video`}</h3>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/NpOhrKbpy0Q" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></YouTube>
    <Alert kind="info" mdxType="Alert">If you are already logged in with Microsoft, you will be logged in automatically.</Alert>
    <h2 {...{
      "id": "staff--public-view"
    }}>{`Staff & Public View`}</h2>
    <p>{`You can view the events calendar from two views, as staff and as a member of the public.`}</p>
    <ol>
      <li parentName="ol">{`When logged in, a circle will appear in the bottom right-hand corner, labelled 'ST', this means you are in the Staff View`}</li>
      <li parentName="ol">{`Click this to access the Public View, the button will change to 'PB', this means you are in the Public View`}</li>
      <li parentName="ol">{`Alternatively, from the events manger home page the 'events calendar' button will take you to the public view of the events calendar`}</li>
      <li parentName="ol">{`To see the Public View of any event, click the 'view event' button`}</li>
    </ol>
    <h3 {...{
      "id": "watch-the-video-1"
    }}>{`Watch The Video`}</h3>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/gYh4LCdtKsk" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></YouTube>
    <h2 {...{
      "id": "search-for-events"
    }}>{`Search for Events`}</h2>
    <p>{`You can search for events from the staff view.`}</p>
    <ol>
      <li parentName="ol">{`Enter Search term in search box`}</li>
      <li parentName="ol">{`The search can include location, category, future event month and past events`}</li>
      <li parentName="ol">{`Click Search`}</li>
      <li parentName="ol">{`Filter the display by, event name, date or location by clicking on the title`}</li>
    </ol>
    <h3 {...{
      "id": "watch-the-video-2"
    }}>{`Watch The Video`}</h3>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/Dqs7wEzgwN0" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></YouTube>
    <Alert kind="info" mdxType="Alert">Event and series ID numbers can be used to search. You can also search for a series using its name.</Alert>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      